.navbar {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}



.navdiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo {
    font-size: 2rem;
    text-decoration: none;
    font-weight: 600;
}

.logo a {
    text-decoration: none;
}

.menu {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
}

.menu li {
    margin-right: 30px;
}

.menu li:last-child {
    margin-right: 0;
}

.menu li a {
    color: #fff;
    font-weight: 600;
    text-decoration: none;
    font-size: 1rem;
}