.jobcard {
    border: 1px solid #ccc;
    padding: 16px;
    margin: 16px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 400px;
  }
  
  .job-card h2 {
    margin-top: 0;
  }
  
  .job-card p {
    margin: 4px 0;
  }
  
  .job-card a {
    display: inline-block;
    margin-top: 16px;
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
  }
  
  .job-card a:hover {
    background-color: #0056b3;
  }
  